import { Bid } from './bid';
import { PriceWithVat } from './Price';
import { CustomBadgeKey } from './CustomBadge';
import { EntryMetadata } from './Metadata/Metadata';

// TODO: Incomplete.
export interface ListEntry {
  readonly companyName: string | null;
  readonly mainPhoto: string;
  readonly title: string;
  readonly subtitle: string | null;
  readonly publicId: number;
  readonly id: number;
  readonly auctionEnd: string;
  readonly bidCount: number;
  readonly vatPerc: number;
  readonly soldToHighestBidder: boolean;
  readonly ended: boolean;
  readonly cancelled: boolean;
  readonly highestBid: PriceWithVat | null;
  readonly startPrice: PriceWithVat;
  readonly continuousAuction: boolean;
  readonly isFastBidPeriodEntry: boolean;
  readonly isOwnPayment: boolean;
  readonly companyId: number;
  readonly source: string | null;
  readonly slug: string;
  readonly isVerifiedEntry: boolean;
  readonly customBadge: CustomBadgeKey | null;
  readonly categoryName: string;
  readonly parentCategoryId: number | null;
  readonly city: string;
  readonly zipCode: string;
  readonly metadata: EntryMetadata | null;
}

export interface EmbeddedEntry extends ListEntry {
  readonly auctionStart: string;
  readonly bidderCount: number;
}

export interface EntryMedia {
  readonly largeImage: string;
  readonly thumbnail: string;
  readonly videoId: string | null;
}

export const enum NetAuctionStatus {
  WAITING_FOR_BIDS = 'W',
  ENDED = 'E',
  APPROVED = 'A',
  REJECTED = 'R',
  SOLD = 'S',
  NO_BIDS = 'N',
}

export interface HermesEntry {
  readonly auctionEnd: string;
  readonly bidCount: number;
  readonly bidderCount: number;
  readonly bids: Bid[];
  readonly highestBid: number;
  readonly id: number;
  readonly isCancelled: boolean;
  readonly isContinuous: boolean;
  readonly isEnded: boolean;
  readonly isSoldToHighestBidder: boolean;
  readonly safeLimit: number | null;
  readonly postTradingStartedAt: string | null;
}

export const enum DeliveryType {
  DELIVERY = 100,
  RETRIEVAL = 200,
}

export interface DeliveryMethod {
  type: DeliveryType;
  expenses: number | null;
  label: string;
  expensesLabel: string;
  additionalNote: string;
  notAvailableLabel: string;
}

export const enum ReceiptType {
  PRIVATE_PERSON = 1,
  COMPANY = 2,
}

export interface ReceiptChoice {
  type: ReceiptType;
  label: string;
}

export interface FinancingOption {
  readonly id: 'op' | 'autolle.com';
  readonly maxPrice: number | null;
  readonly allowedReceiptTypes: ReceiptType[];
}

export interface ListAnalytics {
  listId: Lowercase<string>;
  listName: string;
}
