import { Flex, Checkbox } from '@mezzoforte/forge';
import { useFavorites } from 'hooks/useFavorites';

interface SmsNotificationToggleProps {
  entryId: number;
}

export function ListSmsNotificationToggle({ entryId }: SmsNotificationToggleProps) {
  const { hasFavorite, hasSmsNotification, toggleSmsNotification } = useFavorites();
  const isFavorite = hasFavorite(entryId);

  if (!isFavorite) return null;

  return (
    <Flex align="center" zIndex={11}>
      <Checkbox
        size={{ base: 'sm', md: 'md' }}
        isReadOnly={toggleSmsNotification.status === 'pending'}
        onChange={() => toggleSmsNotification.mutate(entryId)}
        isChecked={hasSmsNotification(entryId)}
      >
        Tekstarimuistutus
      </Checkbox>
    </Flex>
  );
}
